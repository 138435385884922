import { UserRoles } from "~/types";
import {
  isMissingPhoneNumber,
  goToRegisterPhonePage,
  REGISTER_PHONE_PAGE,
} from "@/utils/auth";

export default defineNuxtRouteMiddleware((to, from) => {
  const isAuthenticated = useNuxtApp().$auth.checkAuthentication();

  if (!isAuthenticated && to.path !== "/entrar") {
    return navigateTo("/entrar");
  }

  const goToInitialPages = () => {
    const role = useNuxtApp().$auth.role;
    if (role.value === UserRoles.SUPPLIER) {
      return navigateTo("/contratos");
    } else {
      return navigateTo("/");
    }
  };

  if (isAuthenticated) {
    const user = useNuxtApp().$auth.user;
    const role = useNuxtApp().$auth.role;

    // Se for fornecedor
    if (user.value && role.value === UserRoles.SUPPLIER) {
      // Se for rota diferente de "cadastrar telefone" e estiver faltando o telefone
      if (to.path !== REGISTER_PHONE_PAGE && isMissingPhoneNumber(user.value)) {
        return goToRegisterPhonePage();
      }
      // Se for a rota de cadastrar telefone e já possuir telefone
      if (
        to.path === REGISTER_PHONE_PAGE &&
        !isMissingPhoneNumber(user.value)
      ) {
        return goToInitialPages();
      }
    }

    // Se estiver autenticado e tentando acessar a página de login
    if (to.path === "/entrar") {
      return goToInitialPages();
    }
  }

  return;
});
